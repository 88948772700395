import { IREcomConcierge } from '@ir-engine/ir-ecom-web-ui'
import React, { useEffect, useState } from 'react'

export const BotPage = () => {
  const [params, setParams] = useState<{
    shopifyStoreFrontId: string
    host: string
    scheme: string
  } | null>(null)

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search)
      const shopifyStoreFrontId = queryParams.get('shopifyStoreFrontId') || ''
      const host = window.location.host || ''
      const scheme = window.location.origin || ''

      setParams({ shopifyStoreFrontId, host, scheme })
    }
  }, [])

  if (!params) {
    // You can return a loader here if needed
    return null
  }

  return (
    <main className="pointer-events-auto flex gap-1.5 overflow-auto">
      <div>
        <IREcomConcierge shopifyStoreFrontId={params.shopifyStoreFrontId} host={params.host} scheme={params.scheme} />
      </div>
    </main>
  )
}

export default BotPage
